html,
body {
  height: 100%;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background: none transparent;
  overflow: hidden;
}

body {
  margin: 0;
}
